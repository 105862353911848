@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #00833e #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #00833e;
  border-radius: 10px;
}

.ql-editor { 
  font-size: 16px;
}
 
.CookieConsent {
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 1rem !important;
  border-radius: 12px;
  box-shadow: 0 5px 30px 7px rgba(0, 0, 0, 0.3);
  width: 350px !important;
  font-size: .8rem !important;
}
#rcc-confirm-button {
  border: 1px solid #158A01 !important;
  border-radius: 8px !important;
  transition: all .2s ease !important;
}
#rcc-confirm-button:hover {
  background-color: #158A01 !important;
  border: 1px solid #fff !important;
  color: #fff !important;;  
}
#rcc-decline-button {
  background-color: #707070 !important;
  border: 1px solid #707070 !important;
  border-radius: 8px !important;
  text-decoration: underline !important;
  transition: all .2s ease !important;
}
#rcc-decline-button:hover {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #FF0000 !important;;  
}
/* 60 */
@keyframes opacity-60 {
  0% {opacity: .6;}
  50% {opacity: 1;}
  100% {opacity: .6;}
}
.animation-opacity-60 {
  animation: opacity-60 .5s ease;
}
/* 40 */
@keyframes opacity-40 {
  0% {opacity: .4;}
  50% {opacity: .9;}
  100% {opacity: .4;}
}
.animation-opacity-40 {
  animation: opacity-40 .5s ease;
  animation-delay: 100ms;
}
/* 30 */
@keyframes opacity-30 {
  0% {opacity: .3;}
  50% {opacity: .8;}
  100% {opacity: .3;}
}

.animation-opacity-30 {
  animation: opacity-30 .5s ease;
  animation-delay: 200ms;
}
/* 20 */
@keyframes opacity-20 {
  0% {opacity: .2;}
  50% {opacity: .8;}
  100% {opacity: .2;}
}

.animation-opacity-20 {
  animation: opacity-20 .5s ease;
  animation-delay: 300ms;
}


/* REVERSE ANIM */
/* 60 */
@keyframes opacity-60 {
  0% {opacity: .6;}
  50% {opacity: 1;}
  100% {opacity: .6;}
}
.animation-reverse-opacity-60 {
  animation: opacity-60 .5s ease;
  animation-delay: 300ms;
}
/* 40 */
@keyframes opacity-40 {
  0% {opacity: .4;}
  50% {opacity: .9;}
  100% {opacity: .4;}
}
.animation-reverse-opacity-40 {
  animation: opacity-40 .5s ease;
  animation-delay: 200ms;
}
/* 30 */
@keyframes opacity-30 {
  0% {opacity: .3;}
  50% {opacity: .8;}
  100% {opacity: .3;}
}

.animation-reverse-opacity-30 {
  animation: opacity-30 .5s ease;
  animation-delay: 100ms;
}
/* 20 */
@keyframes opacity-20 {
  0% {opacity: .2;}
  50% {opacity: .8;}
  100% {opacity: .2;}
}

.animation-reverse-opacity-20 {
  animation: opacity-20 .5s ease;
}