.text-stroke-secfive {
  -webkit-text-stroke: 1px #fff;
}

.sectionfive .swiper {
  height: 100%;
}

.sectionfive .swiper-wrapper {
  height: calc(100vh-12rem);
  width: 100vw;
}

.sectionfive .swiper-slide {
  height: 100%;
}
