.text-stroke-news {
  -webkit-text-stroke: 1px #000;
}

.news .swiper {
  height: 100%;
}

.news .swiper-wrapper {
  height: calc(100vh - 16rem);
  width: 100vw;
}

.news .swiper-slide {
  height: 100%;
  cursor: pointer;
  padding: 2rem 1rem;
}

