.chatbot-left-triangle {
    border-radius: 14px 14px 14px 0;
}
.chatbot-left-triangle::before {
    content: "";
    position: absolute;
    top: 20%;
    left: -10px;
    width: 10px;
    height: 10px;
    background-color: white;
    clip-path: polygon(100% 0%, 0 50%, 100% 100%);
}

.chatbot-mess-2 {
    border-radius: 0 14px 14px 0;
}

.chatbot-mess-3 {
    border-radius: 0 14px 14px 14px;
}