.text-stroke {
  -webkit-text-stroke: 1px #fff;
}

.sectionfour .swiper-wrapper {
  height: calc(100vh-12rem);
  width: 100vw;
}

.sectionfour .swiper-slide {
  height: 60vh;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 400px) {
  .sectionfour .swiper-slide {
    height: 35vh;
  }
}

@media only screen and (max-width: 768px) {
  .sectionfour .swiper-slide {
    height: 45vh;
  }
}

@media only screen and (min-width: 1080px) {
  .sectionfour .swiper-slide-active {
    width: 30% !important;
  }
}

.references-image-filter {
  filter: invert(28%) sepia(99%) saturate(6836%) hue-rotate(155deg) brightness(75%) contrast(100%); 
}

.blending-mode {
  background-color: #158A01;
  background-blend-mode: multiply;
}